import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

// Generic
import HubspotForm from '../../elements/HubspotForm/HubspotForm'
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Link from '../../shared/Link/Link'

// Local
import FooterLink from './FooterLink'

// Icons
import LogoFullWhite from '../../../assets/img/logo-full-white.svg'
import DisabilityConfidentLogo from '../../../assets/png/disability-confident-logo.png'

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query footerNavigation {
      items: prismicFooterNavigation {
        data {
          newsletter_title {
            text
          }
          newsletter_body {
            html
          }
          baseline_text {
            html
          }
          baseline_links {
            item_label {
              text
            }
            item_link {
              ...Link
            }
          }
          body {
            ... on PrismicFooterNavigationBodyFooterGroup {
              id
              slice_type
              primary {
                header {
                  text
                }
              }
              items {
                subitem_label {
                  text
                }
                subitem_link {
                  ...Link
                }
                is_cta
              }
            }
            ... on PrismicFooterNavigationBodyFooterSocial {
              id
              slice_type
              primary {
                header {
                  text
                }
              }
              items {
                subitem_platform
                subitem_link {
                  ...Link
                }
              }
            }
          }
        }
      }
      globals: prismicGlobal {
        data {
          footer_hubspot_form_guid
        }
      }
    }
  `)

  return (
    <footer className='bg-midnight'>
      <Container>
        <div className='py-16'>
          <div className='text-center text-white space-y-1 mb-8'>
            <h6 className='h4'>{data.items.data.newsletter_title.text}</h6>
            <HtmlContent
              html={data.items.data.newsletter_body.html}
              className='text-lg c-prose'
            />
          </div>
          {data.globals.data.footer_hubspot_form_guid && (
            <div className='mx-auto max-w-xs sm:max-w-none'>
              <HubspotForm
                formId={data.globals.data.footer_hubspot_form_guid}
                className='c-hubspot-form--footer'
              />
            </div>
          )}
        </div>
        <div className='border-b border-gray-lighter opacity-10' />
        <div className='md:flex justify-between py-16'>
          <div className='pr-16 mb-12 md:mb-0'>
            <LogoFullWhite className='w-64' />
            {/* <DisabilityConfidentLogo className='w-64' /> */}
            <img src={DisabilityConfidentLogo} alt='Disability Confident Logo' className='w-40 pl-4 mt-4' width={160} height={160} />
          </div>
          <div className='md:grid gap-x-16 grid-cols-2 lg:grid-cols-3 md:gap-y-10 space-y-8 md:space-y-0 max-w-md md:max-w-none'>
            {data.items.data.body.map((group) => {
              return (
                <div
                  key={group.id}
                >
                  <h6 className='text-xs font-medium opacity-75 text-white uppercase tracking-wider mb-3'>{group.primary.header.text}</h6>
                  <ul
                    className={classNames({
                      'flex items-center space-x-4': group.slice_type === 'footer_social'
                    }, {
                      'grid grid-cols-2 md:block': group.slice_type !== 'footer_social'
                    })}
                  >
                    {group.items.map((link, linkIndex) => {
                      return (
                        <li key={linkIndex}>
                          <FooterLink
                            type={group.slice_type}
                            className='text-white hover:text-gray-light'
                            {...link}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        </div>
        <div className='border-b border-gray-lighter opacity-10' />
        <div className='sm:flex justify-between py-8 sm:pb-12 text-xs font-medium text-gray-light text-center'>
          <ul className='sm:flex sm:space-x-4 sm:order-2'>
            {data.items.data.baseline_links.map((item, itemIndex) => {
              return (
                <li
                  key={itemIndex}
                  className='mb-2 sm:mb-0'
                >
                  <Link
                    className='opacity-75 hover:text-white hover:opacity-100'
                    {...item.item_link}
                  >
                    {item.item_label.text}
                  </Link>
                </li>
              )
            })}
          </ul>
          <HtmlContent
            html={data.items.data.baseline_text.html.replace('[year]', new Date().getFullYear())}
            className='uppercase tracking-wider sm:order-1 sm:hide-br c-prose'
          />
        </div>
      </Container>
    </footer>
  )
}

export default Footer
